import type { ComputedRef, Ref } from 'vue'
import type { Category, Game } from '../../types'

interface CategoryData {
  category?: string
  title: string
  icon?: string | null
  link?: string
  games?: Game[]
}
interface useCategoryGamesSliderReturn {
  categoryData: ComputedRef<CategoryData>
  gamesTotal: ComputedRef<number | undefined>
}

export function useCategoryGamesSlider(
  categoryCode: Ref<string>,
): useCategoryGamesSliderReturn {
  const { getCategoryByCode } = useCasinoStore()
  const category = computed(
    () => getCategoryByCode(categoryCode.value) as Category,
  )

  const { isMobile } = usePlatform()
  const allowedByCountryOnly = ref(true)

  const { data } = useStFetch('/casino/game/find', {
    method: 'POST',
    body: computed(() => ({
      params: {
        categoryCode: categoryCode.value,
        ...(isMobile.value ? { forMobile: true } : { forDesktop: true }),
        allowedByCountryOnly: allowedByCountryOnly.value,
      },
      pagination: {
        page: 0,
        perPage: 10,
        orderBy: [
          {
            sortOrder: 'DESC' as const,
            fieldName: 'weightInCategory' as const,
          },
        ],
      },
    })),
  })

  const categoryData = computed(() => ({
    category: categoryCode.value,
    title: category.value?.name || categoryCode.value,
    icon: category.value?.icon ?? '',
    link: `/casino/categories/${
      category.value?.siblingCode ?? categoryCode.value
    }`,
    games: data.value?.data.map((game: Game) => ({
      ...game,
      showPlayersCount: true,
    })),
  }))

  const gamesTotal = computed(() => data.value?.paging.total)

  watch(
    data,
    (newData) => {
      if (newData?.data.length === 0 && allowedByCountryOnly.value) {
        allowedByCountryOnly.value = false
      }
    },
    { immediate: true },
  )

  return {
    categoryData,
    gamesTotal,
  }
}
